import React from 'react'
import { l } from '../lib/locale'

import { PagePublicLayout, AccountCreateForm } from '../components'

const CreateAccountTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <PagePublicLayout className="" lang={lang} switcher={page} title={l('create-account-title', lang)}>
      <AccountCreateForm lang={lang} />
    </PagePublicLayout>
  )
}

export default CreateAccountTemplate
